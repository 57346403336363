import { showErrorToastNotification } from "@pollen/ui-library/client/shared/toast-notification/composables/toast-notification.hook";
import type { Ref } from "vue";

import type { BatchRegistrationValidatorEntryWithSessionContext } from "~/business-areas/registration/composables/registration-validator.hook";

export function useRegistrationExecutor(
  organization_id: DatabaseTable<"organizations">["id"],
  entriesPerAvailableSession: Ref<
    {
      entries: BatchRegistrationValidatorEntryWithSessionContext[];
      session: Pick<DatabaseTable<"course_sessions">, "id">;
    }[]
  >,
  options?: {
    onSuccess?: () => void;
  },
) {
  const { $emit } = useNuxtApp();
  const { t } = useI18n();

  return useAsyncData(
    "batch_registration",
    async () => {
      try {
        for (const { session, entries } of entriesPerAvailableSession.value) {
          await $fetch(
            `/api/organizations/${organization_id}/sessions/${session.id}/attendees`,
            {
              method: "POST",
              body: entries
                .filter((entry) => !entry.alreadyRegistered)
                .map((entry) => entry.input),
            },
          );
        }

        showSuccessToastNotification(
          t("registration.submission_success_message"),
        );

        $emit("session_registration_created");

        options?.onSuccess?.();
      } catch {
        showErrorToastNotification(t("registration.submission_error_message"));
      }
    },
    {
      immediate: false,
      deep: false,
    },
  );
}
