<template>
  <app-callout
    class="mb-6 bg-primary-50 text-primary-900"
    :icon="false"
    :title="$t('registration.batch_import.template.title')"
  >
    <ol class="mt-1 grid list-decimal gap-1 pl-4">
      <slot name="instructions">
        <li>{{ $t("registration.batch_import.template.items.download") }}</li>
        <li>{{ $t("registration.batch_import.template.items.validate") }}</li>
      </slot>
    </ol>

    <template #append>
      <app-button
        size="small"
        variant="outlined"
        @click="onTemplateDownloadClick"
      >
        {{ $t("registration.batch_import.template.cta") }}
      </app-button>
    </template>
  </app-callout>

  <app-dropzone :accept="['text/csv']" @drop="processCsvFile">
    <div class="flex flex-col items-center gap-2">
      <app-button class="mb-2" @click="open">
        {{ $t("registration.batch_import.dropzone.cta") }}
      </app-button>
      <p class="text-sm text-subtle">
        {{ $t("registration.batch_import.dropzone.subtitle") }}
      </p>
    </div>
  </app-dropzone>
</template>

<script lang="ts" setup>
const properties = withDefaults(
  defineProps<{
    template?: Record<string, string>[];
  }>(),
  {
    template: () => [
      {
        first_name: "",
        last_name: "",
        email: "",
        job_title: "",
      },
    ],
  },
);

const lines = defineModel<Record<string, string>[] | undefined>();

const { onChange, open } = useFileDialog({ accept: "text/csv" });

onChange((list) => processCsvFile([...(list ?? [])]));

async function processCsvFile(files: File[] | null) {
  if (!files?.length) return;

  lines.value = (await parseCsv(files[0]!)).filter(
    (line) =>
      !properties.template
        .map((templateLine) => templateLine["email"])
        .includes(line["email"]),
  );
}

function onTemplateDownloadClick() {
  return toCsvDownload(properties.template, {
    fileName: "Pollen - Registration template",
  });
}
</script>
