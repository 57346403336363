<template>
  <app-callout
    class="flex-auto bg-error-50 text-error-800"
    data-cy-validator-error
    icon="ph:warning-bold"
  >
    <i18n-t keypath="registration.validation_error" tag="span">
      <template #cta>
        <app-button
          color="danger"
          size="small"
          variant="link"
          @click="emit('reset')"
        >
          {{ $t("registration.validation_error_cta_label") }}
        </app-button>
      </template>
    </i18n-t>
  </app-callout>
</template>

<script lang="ts" setup>
const emit = defineEmits(["reset"]);
</script>
