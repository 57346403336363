<template>
  <div class="overflow-hidden rounded border">
    <table class="w-full text-left text-sm">
      <thead>
        <tr class="bg-gray-50 text-subtle">
          <th><span class="sr-only">Line</span></th>
          <th
            v-for="header in keys"
            :key="header"
            class="font-semibold"
            :class="cellClasses"
          >
            {{ header }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(entry, index) in entries"
          :key="index"
          :class="{
            'bg-gray-50/50': index % 2 !== 0,
            'text-subtle opacity-50': entry.alreadyRegistered,
          }"
        >
          <td
            class="w-px border-t text-xs font-semibold text-subtle"
            :class="cellClasses"
          >
            {{ entry.lineIndex }}
          </td>
          <td
            v-for="key in keys"
            :key="key"
            class="relative border-t"
            :class="[
              cellClasses,
              {
                'bg-error-100 font-semibold text-error-900': !!cellError(
                  key,
                  entry,
                ),
              },
            ]"
          >
            {{ entry.input[key] }}

            <app-icon
              v-if="cellIcon(key, entry)?.icon"
              v-tooltip="cellIcon(key, entry)?.tooltip"
              class="absolute right-2 top-1/2 -translate-y-1/2"
              :class="cellIcon(key, entry)?.class"
              :icon="cellIcon(key, entry)!.icon"
              :size="16"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import type { BatchRegistrationValidatorEntryWithSessionContext } from "~/business-areas/registration/composables/registration-validator.hook";
import type { I18nMessage } from "~/core/i18n/i18n.model";

const properties = defineProps<{
  entries: BatchRegistrationValidatorEntryWithSessionContext[];
}>();

const keys = computed(() =>
  Object.keys(properties.entries[0]?.input ?? {}).filter(
    (key) => key !== "sessions",
  ),
);

const cellClasses = "py-1 px-2 border-r last:border-r-0";

function cellError(key: string, entry: (typeof properties.entries)[number]) {
  return entry.errors.find((error) => error.path[1] === key)?.message;
}

function cellIcon(
  key: string,
  entry: (typeof properties.entries)[number],
): { icon: string; tooltip?: I18nMessage; class?: string } | undefined {
  const isLastCell = keys.value.indexOf(key) === keys.value.length - 1;

  if (isLastCell && entry.alreadyRegistered) {
    return {
      icon: "ph:info",
      tooltip:
        "Un utilisateur est déjà inscrit à cette session avec cette adresse e-mail",
    };
  }

  if (isLastCell && entry.errors.length === 0) {
    return {
      icon: "ph:check-bold",
      class: "text-success",
    };
  }

  const error = cellError(key, entry);
  if (error) {
    return {
      icon: "ph:warning-bold",
      tooltip: error,
    };
  }
}
</script>
